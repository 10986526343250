/**   
 * 清分管理/结算管理api
 */
export default {
  /**
  * 
* 结算明细
*/
  // 列表
  getSettlementInfoList: "/settlement_order/settlement_order_list",
  // 列表-查看详情
  getSettlementInfoDetail: "/settlement_order/settlement_order_info",
    // 充值明细详情
  getShopMemberRechargeByCode: "/settlement_order/getShopMemberRechargeByCode",

  /**
   * 
 * 提现申请
 */
  // 列表 
  getShopExtractApplyList: "/shopExtractApply/pageList",
  // 查看详情
  getShopExtractApplyDetails: "/shopExtractApply/pageListDetails",
  // 提现
  shopExtractApply: "/shopExtractApply/shopExtractApply",
  // 列表顶部账户总览
  getSelectSettlementAccount: "settlement_account/select_shop_settlement_account",


  // 提现弹窗-下拉选择收款账户
  getExtractAccountDrop: "/extractAccount/drop",
  // 收款账号管理-列表
  getExtractAccountListPage: "/extractAccount/listPage",
  // 收款账号管理-新增
  getExtractAccountAdd: "/extractAccount/add",
  // 收款账号管理-编辑
  getExtractAccountUpdate: "/extractAccount/update",
  // 收款账号管理-删除
  getExtractAccountDelete: "/extractAccount/delete",


  // 欠费缴纳
  selectSettlementArrearsPayList: "/settlementArrearsPay/selectSettlementArrearsPay",
  // 欠费缴纳详情
  selectSettlementArrearsPayDet: "/settlementArrearsPay/selectSettlementArrearsPayDetail",
  //欠费缴纳二维码
 payInfo: "/settlementArrearsPay/payInfo",
  // 检查支付状态
  select_pay: "/settlementArrearsPay/selectPay",
};