// 结算管理
export default [
  {
    path: '/settlement',
    meta: { title: '结算管理' },
    component: () => import('../views/settlement/index.vue'),
    // redirect: '/settlement/saleDetail',
    children: [
      {
        path: '/settlement/saleDetail',
        meta: { title: '结算明细', isMenu: true },
        component: () => import('../views/settlement/saleDetail.vue'),
      },
      {
        path: '/settlement/arrearage',
        name:'arrearage',
        meta: { title: '欠费缴纳', isMenu: true },
        component: () => import('../views/settlement/arrearage.vue'),
      },
      {
        path: '/settlement/detail/arrearageDet',
        name:'arrearageDet',
        meta: { title: '欠费缴纳详情', },
        component: () => import('../views/settlement/detail/arrearageDet.vue'),
      },
      {
        path: '/settlement/detail/orderDet',
        name:'orderDet',
        meta: { title: '欠费缴纳订单详情', },
        component: () => import('../views/settlement/detail/orderDet.vue'),
      },
      {
        path: '/settlement/detail/paymentPage',
        name: 'paymentPage',
        meta: { title: '欠费缴纳页面' },
        component: () => import('../views/settlement/detail/paymentPage.vue'),
      },
      {
        path: '/settlement/withdrawalApplication',
        meta: { title: '提现申请', isMenu: true },
        component: () => import('../views/settlement/withdrawalApplication.vue'),
      },
      {
        path: '/settlement/detail/accountManagement',
        meta: { title: '收款账号管理', },
        component: () => import('../views/settlement/detail/accountManagement.vue'),
      },
      {
        path: '/settlement/detail/applyDetail',
        meta: { title: '提现申请详情', },
        component: () => import('../views/settlement/detail/applyDetail.vue'),
      },
      {
        path: '/settlement/detail/settlementDetail',
        meta: { title: '结算明细详情', },
        component: () => import('../views/settlement/detail/settlementDetail.vue'),
      },
      {
        path: '/settlement/detail/membershipCardPayDetail',
        meta: { title: '充值明细详情', },
        component: () => import('../views/settlement/detail/membershipCardPayDetail.vue'),
      },
    ],
  },
]