/**
 * 进货管理api
 */
export default {
  // 进货管理->商品列表
  purchaseList: '/purchase/listPc',
  // 进货记录
  purchase_cart: '/purchase_cart/shop_select_purchase_order',
  // 分类列表
  categoryLIst: '/purchase/categoryList',
  // 进货记录详情
  purchase_cart_info: '/purchase_cart/shop_select_purchase_order_info',
  // 确认收货
  shop_confirm: '/purchase_cart/shop_confirm_receipt',
  // 新增购物车
  save_purchase_cart: '/purchase_cart/save_purchase_cart',
  // 查询
  select_purchase_cart: '/purchase_cart/select_purchase_cart',
  // 删除购物车
  delete_purchase_cart: '/purchase_cart/delete_purchase_cart',
  // 修改购物车
  update_purchase_cart: '/purchase_cart/update_purchase_cart',
  // 新增采购单
  shop_save_purchase_order: '/purchase_cart/shop_save_purchase_order',
  // 新增采购单-商超
  purchase_cart_shop_info: '/purchase_cart/shop_info',
  // 删除进货单
  purchase_cart_del: '/purchase_cart/cancel_confirm_shipment',
  // 查询商品套餐包
  selectPurchasePackage: '/purchasePackage/selectPurchasePackage',
  // 查询套餐包内的商品(post)
  selectPurchasePackageProduct: '/purchasePackage/selectPurchasePackageProduct',
  // 查询套餐内的分类
  selectProductCategory: '/purchasePackage/selectPurchasePackageProductCategory',
  // 加入进货单
  saveInPurchaseProduct: '/purchasePackage/saveInPurchaseProduct',
  categoryListAll: '/categoryInfo/list', // 分类列表
  // 查询购物车内的分类
  select_purchase_cart_category:'/purchase_cart/select_purchase_cart_category'

};
