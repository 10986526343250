// 技术服务费
export default [
  {
    path: '/technicalServiceFee',
    meta: { title: '技术服务费' },
    component: () => import('../views/technicalServiceFee/index.vue'),
    // redirect: '/technicalServiceFee/serviceFeeList',
    children: [
      {
        path: '/technicalServiceFee/serviceFeeList',
        meta: { title: '服务费明细', isMenu: true },
        component: () => import('../views/technicalServiceFee/serviceFeeList.vue'),
      },
      {
        path: '/technicalServiceFee/payServiceFee',
        meta: { title: '缴纳服务费', isMenu: true },
        component: () => import('../views/technicalServiceFee/payServiceFee.vue'),
      },

      {
        path: '/technicalServiceFee/detail/payServiceFeeDetail',
        meta: { title: '缴纳服务费详情' },
        component: () => import('../views/technicalServiceFee/detail/payServiceFeeDetail.vue'),
      },

    ],
  },
];
