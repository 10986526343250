// 店铺设置
export default [
  {
    path: '/shopSetup',
    meta: { title: '店铺设置' },
    component: () => import('../views/shopSetup/index.vue'),
    // redirect: '/shopSetup/storeSettings',
    children: [
      {
        path: '/shopSetup/storeSettings',
        meta: { title: '店铺设置', isMenu: true },
        component: () => import('../views/shopSetup/storeSettings.vue'),
      },
      {
        path: '/shopSetup/detail/info',
        meta: { title: '店铺详情' },
        component: () => import('../views/shopSetup/detail/info.vue'),
      },
      {
        path: '/shopSetup/detail/edit',
        meta: { title: '店铺编辑' },
        component: () => import('../views/shopSetup/detail/edit.vue'),
      },
      {
        path: '/shopSetup/detail/add',
        meta: { title: '店铺新增' },
        component: () => import('../views/shopSetup/detail/add.vue'),
      },

    ],
  },
];
