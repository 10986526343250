// 库存管理
export default [
  {
    path: '/inventory',
    meta: { title: '库存管理' },
    component: () => import('../views/inventory/index.vue'),
    // redirect: '/inventory/definiteDetail',
    children: [
      {
        path: '/inventory/definiteDetail',
        meta: { title: '库存明细', isMenu: true },
        component: () => import('../views/inventory/definiteDetail.vue'),
      },
      {
        path: '/inventory/statistics',
        meta: { title: '库存统计', isMenu: true },
        component: () => import('../views/inventory/statistics.vue'),
      },
      {
        path: '/inventory/notice',
        meta: { title: '库存预警通知', isMenu: true },
        component: () => import('../views/inventory/notice.vue'),
      },
      {
        path: '/inventory/detail/noticeView',
        meta: { title: '库存预警通知-查看', isMenu: true },
        component: () => import('../views/inventory/detail/noticeView.vue'),
      },
      {
        path: '/inventory/setting',
        meta: { title: '库存预警设置', isMenu: true },
        component: () => import('../views/inventory/setting.vue'),
      },
      {
        path: '/inventory/validityWarning',
        meta: { title: '保质期预警通知', isMenu: true },
        component: () => import('../views/inventory/validityWarning.vue'),
      },
      {
        path: '/inventory/warningSetting',
        meta: { title: '保质期预警设置', isMenu: true },
        component: () => import('../views/inventory/warningSetting.vue'),
      },
      {
        path: '/inventory/records',
        meta: { title: '盘存记录', isMenu: true },
        component: () => import('../views/inventory/records.vue'),
      },
      {
        path: '/inventory/detail/recordsView',
        meta: { title: '盘存记录-查看', isMenu: true },
        component: () => import('../views/inventory/detail/recordsView.vue'),
      },
    ],
  },
];
