// 营销管理
export default [
  {
    path: "/marketing",
    meta: { title: "营销管理" },
    component: () => import("../views/marketing/index.vue"),
    children: [
      {
        path: "/marketing/member",
        meta: { title: "会员管理", isMenu: true },
      },
      {
        path: "/marketing/member/memberList",
        meta: { title: "会员列表", isMenu: true },
        component: () => import("../views/marketing/memberList.vue"),
      },
      {
        path: "/marketing/member/recharge",
        meta: { title: "充值记录", isMenu: true },
        component: () => import("../views/marketing/recharge.vue"),
      },
      {
        path: "/marketing/member/bill",
        meta: { title: "账单明细", isMenu: true },
        component: () => import("../views/marketing/bill.vue"),
      },
      {
        path: "/marketing/member/discount",
        meta: { title: "充值优惠", isMenu: true },
        component: () => import("../views/marketing/discount.vue"),
      },
      {
        path: "/marketing/detail/billDetail",
        meta: { title: "账单明细详情" },
        component: () => import("../views/marketing/detail/billDetail.vue"),
      },
      // -----
      {
        path: "/marketing/coupon",
        meta: { title: "优惠券管理" },
      },
      {
        path: "/marketing/coupon/couponList",
        meta: { title: "优惠券列表", isMenu: true },
        component: () => import("../views/marketing/couponList.vue"),
      },
      {
        path: "/marketing/coupon/detail/couponDetail",
        name: "couponDetail",
        meta: { title: "" },
        component: () => import("../views/marketing/detail/couponDetail.vue"),
      },
      {
        path: "/marketing/coupon/getRecord",
        meta: { title: "领券记录", isMenu: true },
        component: () => import("../views/marketing/getRecord.vue"),
      },
    ],
  },
]
