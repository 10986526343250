/**
 * 接口统一管理
 */
import system from "./system.js";
import common from "./common.js";
import coupon from "./coupon.js";
import material from "./material.js";
import procure from "./procure.js";
import shop from "./shop.js";
import product from "./product.js";
import settlement from "./settlement.js";
import order from "./order.js";
import inventory from "./inventory.js";
import technicalServiceFee from "./technicalServiceFee.js";
import business from "./business.js";
import contract from "./contract.js";

const apiAll = [
    system,
    common,
    coupon,
    material,
    procure,
    shop,
    product,
    settlement,
    order,
    inventory,
    technicalServiceFee,
    business,
    contract,
]

// 判断接口是否已存在
function isKeyQepeat(arr) {
    let keyArr = [], apiObj = {};
    arr.forEach((item) => {
        keyArr.push(...Object.keys(item))
        Object.assign(apiObj, item)
    })
    try {
        keyArr.forEach((item, i, arr) => {
            if (arr.indexOf(item) !== i) {
                throw new Error(`接口 key 重复项: ${item}`)
            }
        })
    } catch (error) {
        console.error('[错误]:', error);
        return null
    }
    return apiObj
}
const apiDesc = isKeyQepeat(apiAll)
//进行统一导出 
export default {
    ...apiDesc
}