// 进货管理
export default [
  {
    path: '/procure',
    meta: { title: '进货管理' },
    component: () => import('../views/procure/index.vue'),
    // redirect: '/procure/list',
    children: [
      {
        path: '/procure/list',
        meta: { title: '商品列表', isMenu: true },
        component: () => import('../views/procure/list.vue'),
      },

      {
        path: '/procure/indent',
        meta: { title: '进货单', isMenu: true },
        component: () => import('../views/procure/indent.vue'),
      },
      {
        path: '/procure/purchaseRecords',
        meta: { title: '进货记录', isMenu: true },
        component: () => import('../views/procure/purchaseRecords.vue'),
      },
      {
        path: '/procure/purchasePackage',
        meta: { title: '进货套餐', isMenu: true },
        component: () => import('../views/procure/purchasePackage.vue'),
      },
      {
        path: '/procure/detail/purchasePackageDetail',
        meta: { title: '进货套餐-查看' },
        component: () => import('../views/procure/detail/purchasePackageDetail.vue'),
      },
      {
        path: '/procure/detail/info',
        meta: { title: '进货记录-查看' },
        component: () => import('../views/procure/detail/info.vue'),
      },
      {
        path: '/procure/detail/procurementDetail',
        meta: { title: '采购详情' },
        component: () => import('../views/procure/detail/procurementDetail.vue'),
      },
    ],
  },
];
