// 店铺管理
export default [
  {
    path: '/shop',
    meta: { title: '店铺管理' },
    component: () => import('../views/shop/index.vue'),
    // redirect: '/shop/shopBanner',
    children: [
      // {
      //   path: '/shop/shopSetup',
      //   meta: { title: '店铺设置', isMenu: true },
      //   component: () => import('../views/shop/shopSetup.vue'),
      // },
      // {
      //   path: '/shop/detail/info',
      //   meta: { title: '店铺详情'},
      //   component: () => import('../views/shop/detail/info.vue'),
      // },
      // {
      //   path: '/shop/detail/edit',
      //   meta: { title: '店铺编辑' },
      //   component: () => import('../views/shop/detail/edit.vue'),
      // },
      {
        path: '/shop/shopBanner',
        meta: { title: '轮播图管理', isMenu: true },
        component: () => import('../views/shop/shopBanner.vue'),
      },
      {
        path: '/shop/shopUser',
        meta: { title: '店铺用户', isMenu: true },
        component: () => import('../views/shop/shopUser.vue'),
      },
    ],
  },
];
