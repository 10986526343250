import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"
import system from "./system"
import shop from "./shop"
import procure from "./procure"
import product from "./product"
import settlement from "./settlement"
import marketing from "./marketing"
import order from "./order"
import inventory from "./inventory"
import shopSetup from "./shopSetup"
import technicalServiceFee from "./technicalServiceFee"
import business from "./business"
import contract from "./contract"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    meta: { title: "商超后台管理系统" },
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        meta: { title: "首页" },
        component: () => import("../views/Home.vue"),
      },

      ...system,
      ...shop,
      ...procure,
      ...product,
      ...settlement,
      ...marketing,
      ...order,
      ...inventory,
      ...shopSetup,
      ...technicalServiceFee,
      ...business,
      ...contract,
    ],
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register.vue"),
    meta: { title: "商超注册" },
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "商超管理后台-登录" },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/loginShop",
    name: "loginShop",
    meta: { title: "商超管理后台-选择店铺" },
    component: () => import("../views/loginShop.vue"),
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    meta: { title: "忘记密码" },
    component: () => import("../views/forgotPassword.vue"),
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "服务器拒绝访问或页面不存在" },
    component: () => import("../views/404.vue"),
  },
]

const router = new VueRouter({
  routes,
})

const originaPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originaPush.call(this, location).catch(err => err)
}

router.beforeEach((to, form, next) => {
  const token = store.state.token

  if (to.path == "/register" || to.path == "/forgotPassword") {
    next()
  } else if (!token && to.path !== "/login") {
    store.dispatch("logout")
    next("/login")
  } else if (token && to.path == "/login") {
    store.dispatch("logout")
    localStorage.clear()
    next()
  } else {
    next()
  }
})

router.afterEach(to => {
  if (to.meta.title) document.title = to.meta.title
})
export default router
