/**
 * 店铺管理api
 */
export default {
  // 删除轮播图
  delBanner: '/adCarousel/delete',
  // 轮播图列表
  pageList: '/adCarousel/pageList',
  // 新增轮播图
  addBanner: '/adCarousel/save',
  // 查询轮播图详情{ id }
  select_info: '/adCarousel/select_info/',
  // 修改轮播图
  updateBanner: '/adCarousel/update',
  // 店铺管理->店铺用户列表
  shopMallUserList: '/shopMallUser/pageList',
  // 店铺管理->店铺设置->删除{shopId}
  shopSettingDel: '/shopSetting/delete/',
  // 店铺管理->店铺设置->查看
  shopDetails: '/shopInfo/details/',
  // 店铺管理 -> 店铺设置列表
  shopList: '/shopInfo/pageList',
  // 店铺管理->店铺设置->编辑
  shopUpdate: '/shopInfo/update',
  // 店铺管理->店铺设置->修改状态
  updateStatus: '/shopSetting/updateStatus',
  // 商超新增
  shopAdd: '/shopInfo/add',
  // 启动状态
  shopStatus: '/shopInfo/updateStatus',
  // 商超删除/撤销
  shopDelete:'/shopInfo/delete/'
};
