import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import axios from "./request/http"
import api from "./request/api"
import ElementUI from "element-ui"
import "./assets/css/element-variables.scss"
import "./assets/css/globle.scss"
import "./assets/font/fonts.css"
import ButtonPermission from "./utils/permission.js"
import dayjs from "dayjs"

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$dayjs = dayjs
Vue.prototype.$sysCode = "supermarket" // 系统编码 (supplier:供应商 purchase:采购 portal:门户 mall:商城 supermarket:商超)

// 用于缓存页面的搜索条件
Vue.prototype.$saveOrUpdateCache = function (key, data) {
  if (Object.keys(data).length > 0) {
    localStorage.setItem(key, JSON.stringify(data))
  } else {
    localStorage.removeItem(key)
  }
}

Vue.use(ElementUI)
Vue.use(ButtonPermission) // 按钮权限
Vue.component("back", () => import("./components/back"))
Vue.component("backup", () => import("./components/backup"))
Vue.component("moduleView", () => import("./components/moduleView"))
Vue.component("headers", () => import("./components/Headers"))
Vue.component("footers", () => import("./components/Footers"))

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
