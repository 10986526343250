/**   
 * 技术服务费api
 */
export default {
  /**
  * 
* 服务费明细
*/
  getTechDetailInfoList: '/tech_fee/tech_detail_info',  // 列表

  /**
   * 
 * 缴纳服务费
 */
  getTechFeeList: '/tech_fee/tech_info_list',  // 列表
  getTechFeeInfo: '/tech_fee/tech_info_info',  // 详情

};