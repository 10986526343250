// 订单管理
export default [
  {
    path: '/order',
    meta: { title: '订单管理' },
    component: () => import('../views/order/index.vue'),
    // redirect: '/order/onlineOrder',
    children: [
      {
        path: '/order/onlineOrder',
        name: 'onlineOrder',
        meta: { title: '线上订单', isMenu: true },
        component: () => import('../views/order/onlineOrder.vue'),
      },
      {
        path: '/order/detail/distributionDet',
        meta: { title: '配送订单详情' },
        name: 'distributionDet',
        component: () => import('../views/order/detail/distributionDet.vue'),
      },
      {
        path: '/order/detail/selfTakeDet',
        meta: { title: '自提订单详情' },
        name: 'selfTakeDet',
        component: () => import('../views/order/detail/selfTakeDet.vue'),
      },
      {
        path: '/order/offlineOrder',
        meta: { title: '线下订单', isMenu: true },
        component: () => import('../views/order/offlineOrder.vue'),
      },
      {
        path: '/order/detail/offlineOrderDet',
        meta: { title: '线下订单详情' },
        name: 'offlineOrderDet',
        component: () => import('../views/order/detail/offlineOrderDet.vue'),
      },
      {
        path: '/order/afterSaleOrder',
        meta: { title: '售后订单', isMenu: true },
        component: () => import('../views/order/afterSaleOrder.vue'),
      },
      {
        path: '/order/detail/afterSaleOrderDet',
        meta: { title: '售后订单详情' },
        name: 'afterSaleOrderDet',
        component: () => import('../views/order/detail/afterSaleOrderDet.vue'),
      },
    ],
  },
];
