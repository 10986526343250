/**   
 * 公共api
 */
export default {
  //登录
  login: "/userLogin/login",
  // 商超用户登录
  shopUserLogin: '/userLogin/shopUserLogin',
  //文件上传，返回地址
  uploadFile: "/attachment/uploadFile",
  //商超注册
  shopInfoRegister: "/shopInfo/register",
  //查询商超手机号是否可用/{supplierPhone}
  shopInfoContactTel: "/shopInfo/contactTel",
  //查询商超名称是否可用/{shopName}
  shopInfoShopName: "/shopInfo/shopName",
  //学校列表
  schoolList: "/schoolInfo/list",
  //营业执照文字识别
  getBizLicenseInfoOCR: "/sysOCR/getBizLicenseInfo",
  //获取短信验证码
  SMSCode: "/userLogin/SMSCode",
  //提交短信验证码
  CheckSMSCode: "/userLogin/CheckSMSCode",
  //商超找回密码
  retrievePassword: "/shopInfo/retrievePassword",
  // 商超个人信息
  getShopInfo: '/shopInfo/getShopInfo',
  // 个人信息>确认
  shopInfoUpdateShopInfo: '/shopInfo/updateShopInfo',
  // 个人信息>修改密码
  shopInfoUpdatePassword: '/shopInfo/updatePassword',
  // 商超列表 
  getShopList: '/shopInfo/pageList',
  // 选择商超/{shopId}
  choiceShop: '/userLogin/choice',
  // 首页
  homeList: '/shopHome/list', // 新增数据集合
  homeToday: '/shopHome/today', // 当日新增

  getPlatformIm: '/tencentIm/getPlatformIm', // 获取接收方（总后台）accountId
  // pcLogin:'/tencentIm/pcLogin', // pc登录im
  loginIM: '/tencentIm/login', // 登录腾讯im
  // 查询省市区
  getDistrictInfo:'/districtInfo/getDistrictInfo' 
  };