/**   
 * 订单管理api
 */
export default {
  // 线上线下列表   订单来源：1线下；2线上商城(必填)
  ordersList: "/shop_order/select_online_orders",
  // 线上订单导出
  ordersListExport: "/shop_order/select_online_orders_export",
  // 取消订单
  cancelOrders: "/shop_order/cancel_order",
  // 接单
  takingOrders: "/shop_order/taking_order_button",
  // 到店自取
  ordersPickup: "/shop_order/pick_up_to_shop",
  // 线上退款
  refundOrders: "/shop_order/refund_order",
  // 线下退款
  refundOffOrders: "/shop_order/off_order_refund",
  // 线上线下列表详情
  ordersDet: "/shop_order/select_online_orders_info",
  // 线上订单运费修改
  ordersFreight: "/shop_order/update_order_freight",
  // 售后订单列表
  ordersRefundList: "/shop_order_refund/select",
  // 售后订单详情
  ordersRefundDet: "/shop_order_refund/get",
  // 售后订单拒绝退款+同意退款处理
  ordersRefundUpdate: "/shop_order_refund/update_status",
  // 售后订单二次弹窗详情
  ordersRefundTowUpdate: "/shop_order_refund/select_order_refund_info",
  
};